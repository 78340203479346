
export default {
    name: "PreFooter",
    components: {
      DrupalBlocks: () => import("@/components/widget/DrupalBlocks")
    },
    data() {
      return {
        PreFooter: [],
      }
    },
    async fetch() {
      try {
        this.PreFooter = await this.$store.$regionRepository.getByIdWithParam({id: 'pre_footer'})
      } catch (e) {
        this.PreFooter = []
      }
    },
  }
